import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";

const IndexPage = (props) => (
  <Layout>
    <SEO
      title="Constanzia Yurashko | Women's Designer Clothing"
      meta={[
        `Exclusive women's ready-to-wear fashion by designer Constanzia Yurashko.`,
      ]}
    />

    <div className="home-banner">
      <Img fluid={props.data.IMG_2742.childImageSharp.fluid} />
      <h1 className="home-banner__text">
        <Link to="/collections/nothingness/">N o t h i n g n e s s</Link>
      </h1>
    </div>
  </Layout>
);

export default IndexPage;

export const fluidImageHome = graphql`
  fragment fluidImageHome on File {
    childImageSharp {
      fluid(maxWidth: 2560) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    IMG_2742: file(
      relativePath: { eq: "collections/nothingness/hero_image.jpg" }
    ) {
      ...fluidImageHome
    }
  }
`;
